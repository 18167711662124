import { Box, Button, FormLabel, Input } from '@chakra-ui/react'
import { useState } from 'react'

export function OverrideOrgName() {
  const [overrideOrgName, setOverrideOrgName] = useState<string>(
    (window && window.localStorage.getItem('overrideOrgName')) ?? ''
  )

  return (
    <Box>
      <FormLabel>Override Org Name</FormLabel>
      <Box css={{ display: 'flex', gap: '8px' }}>
        <Input
          placeholder="Default"
          value={overrideOrgName}
          onChange={(e) => {
            setOverrideOrgName(e.currentTarget.value)
          }}
        />
        <Button
          onClick={() => {
            if (window) {
              window.localStorage.setItem('overrideOrgName', overrideOrgName)
              window.location.reload()
            }
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  )
}
