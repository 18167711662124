import {
  Button,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useAuthInfo } from '@propelauth/react'
import axios from 'axios'
import FormData from 'form-data'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { HiOutlineSparkles } from 'react-icons/hi'

const MotionButton = motion(Button)

const Wishlist: React.FC = () => {
  const authInfo = useAuthInfo()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [reason, setReason] = useState('')

  const handleSubmit = async () => {
    setIsLoading(true)

    const email = authInfo?.user?.email ?? 'unknown'
    const formData = new FormData()
    formData.append('email', email)
    formData.append('reason', 'Mirrorful Feedback')
    formData.append('content', reason)

    await axios.post(`https://leftonread.herokuapp.com/api/help`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    setIsLoading(false)
    onClose()

    toast({
      title: 'Wish submitted!',
      description: 'Your wish has been sent to the Mirrorful team.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  return (
    <>
      <MotionButton
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        variant="outline"
        size="xs"
        rightIcon={<Icon as={HiOutlineSparkles} />}
        onClick={() => {
          if (typeof window !== 'undefined') {
            window.open('https://www.designpatterns.io/ai')
          }
        }}
        color="var(--color-text-secondary)"
      >
        <span>Create with Patterns AI</span>
      </MotionButton>

      {/* <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feature request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              borderRadius="4"
              size="sm"
              color="var(--color-text-secondary)"
              placeholder="Wish Mirrorful could do something new?"
            />
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              size="sm"
              color="white"
              backgroundImage={`linear-gradient(to bottom right, #805AD5, #553C9A)`}
              _hover={{
                backgroundImage: `linear-gradient(to bottom right, #553C9A, #805AD5)`,
              }}
              _active={{
                backgroundImage: `linear-gradient(to bottom right, #553C9A, #805AD5)`,
              }}
              shadow="lg"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </>
  )
}

export default Wishlist
