import { getOrgName } from '@web/context'
import {
  assertToken,
  assertTokenGroup,
  TMetadata,
  TTheme,
  TToken,
  TTokenGroup,
} from '@web/types'

export function getCssVarValue(cssVarExpression: string) {
  // Extract var name from the provided expression
  const varNameMatch = cssVarExpression.match(/var\((--[^)]+)\)/)

  // If there's no match, return the cssVarExpression itself
  if (!varNameMatch) return cssVarExpression

  const varName = varNameMatch[1]

  // Get the computed style of the root element
  const style = getComputedStyle(document.documentElement)

  // Get the property value and trim any whitespace
  const value = style.getPropertyValue(varName).trim()

  return value
}

export const addTokenOrGroupToTheme = ({
  path,
  target,
  theme,
}: {
  path: string
  target: TTokenGroup | TToken
  theme: TTheme
}): TTheme => {
  const updatedTokens = structuredClone(theme.tokens)

  if (!assertTokenGroup(updatedTokens)) {
    throw new Error(`Theme colors are not a token group.`)
  }

  const keys = path.split('.')

  let currentObject: TTokenGroup = updatedTokens

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      currentObject[key] = target
    } else {
      const nextObj = currentObject[key]

      if (!nextObj) {
        const newGroup: TTokenGroup = {}
        currentObject[key] = newGroup
        currentObject = newGroup
      } else if (assertTokenGroup(nextObj)) {
        currentObject = nextObj
      } else {
        throw new Error(`This path already exists.`)
      }
    }
  })

  return {
    id: theme.id,
    name: theme.name,
    tokens: updatedTokens,
  }
}

// TODO(teddy): Need algorithm here to maintain order
export const editTokenOrGroupInTheme = ({
  originalPath,
  updatedPath,
  target,
  theme,
}: {
  originalPath: string
  updatedPath: string
  target: TTokenGroup | TToken
  theme: TTheme
}): TTheme => {
  let updatedTheme = structuredClone(theme)

  try {
    updatedTheme = deleteTokenOrGroupFromTheme({
      path: originalPath,
      theme,
    })

    updatedTheme = addTokenOrGroupToTheme({
      path: updatedPath,
      target,
      theme: updatedTheme,
    })
  } catch (e) {
    throw e
  }

  return updatedTheme
}

export const deleteTokenOrGroupFromTheme = ({
  path,
  theme,
}: {
  path: string
  theme: TTheme
}): TTheme => {
  const updatedTokens = structuredClone(theme.tokens)
  if (!assertTokenGroup(updatedTokens)) {
    throw new Error(`Theme colors are not a token group.`)
  }

  const keys = path.split('.')

  let currentObject: TTokenGroup = updatedTokens
  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      delete currentObject[key]
    } else {
      const nextObj = currentObject[key]

      if (!nextObj) {
        throw new Error(`Non-existent path.`)
      } else if (assertTokenGroup(nextObj)) {
        currentObject = nextObj
      } else {
        throw new Error(`This path already exists.`)
      }
    }
  })

  return {
    id: theme.id,
    name: theme.name,
    tokens: updatedTokens,
  }
}

/** This function has a bug and does not resolve variables - use resolveValueForGeneration */
export const resolveTokenValue = <K extends string | number>({
  value,
  colors,
  metadata,
  orgName,
  notPx,
}: {
  value: K
  colors: TTokenGroup
  metadata: TMetadata
  orgName: string
  notPx?: boolean
}) => {
  if (notPx) {
    return `${value}`
  }
  if (typeof value === 'number') {
    return `${value}px`
  }

  if (
    value.startsWith('{role:') &&
    value.endsWith('}') &&
    typeof value === 'string'
  ) {
    let referencedValue = ''

    const role = value.replace('}', '').split('role:')[1].split('.')[0]

    const matchedRole = metadata.colorRoles?.find((r) => r.role === role)
    if (!matchedRole) {
      if (role === 'primary') {
        return 'blue'
      } else if (role === 'success') {
        return 'green'
      } else if (role === 'error') {
        return 'red'
      }
      return 'gray'
    }

    const replacedValue = value.replace(`role:${role}`, matchedRole.colorName)

    try {
      referencedValue = resolveValueForGeneration({
        value: replacedValue,
        orgName,
        metadata,
      })
    } catch (e) {
      referencedValue = '!ERROR'
    }
    return referencedValue
  } else if (value.startsWith('{') && value.endsWith('}')) {
    let referencedValue = ''
    try {
      referencedValue = resolveReference({ path: value, colors })
    } catch (e) {
      referencedValue = '!ERROR'
    }
    return referencedValue
  }

  return value
}

const resolveReference = ({
  path,
  colors,
}: {
  path: string
  colors: TTokenGroup
}) => {
  const keys = path.substring(1, path.length - 1).split('.')

  let currentReference: TTokenGroup | TToken | undefined = colors
  keys.forEach((key) => {
    if (currentReference && assertTokenGroup(currentReference)) {
      currentReference = currentReference[key]
    } else {
      throw new Error('This reference is not a valid path.')
    }
  })
  if (!assertToken(currentReference)) {
    throw new Error('This reference is not a valid token.')
  }

  return currentReference.value
}

export const resolveTokenValueForGeneration = ({
  prepend,
  value,
}: {
  prepend?: string
  value: string
}) => {
  if (value.startsWith('{') && value.endsWith('}')) {
    let referencedValue = ''
    try {
      referencedValue = resolveReferenceAsVariable({
        prepend,
        path: value,
      }).cssVariable
    } catch (e) {
      referencedValue = '!ERROR'
    }
    return referencedValue
  }

  return value
}

/* This resolves variables and seems to be the "latest and greatest" function */
export const resolveValueForGeneration = ({
  value,
  orgName,
  metadata,
  name,
}: {
  value: string | number | boolean
  orgName: string
  metadata: TMetadata
  name?: string // css property
}): string => {
  if (
    typeof value === 'string' &&
    value.startsWith('{role:') &&
    value.endsWith('}')
  ) {
    let referencedValue = ''

    const role = value.replace('}', '').split('role:')[1].split('.')[0]

    const matchedRole = metadata.colorRoles?.find((r) => r.role === role)
    if (!matchedRole) {
      if (role === 'primary') {
        return 'blue'
      } else if (role === 'success') {
        return 'green'
      } else if (role === 'error') {
        return 'red'
      }
      return 'gray'
    }

    const replacedValue = value.replace(`role:${role}`, matchedRole.colorName)
    try {
      referencedValue = resolveReferenceAsVariable({
        prepend: `${orgName}-`,
        path: `${replacedValue}`,
      }).cssVariable
    } catch (e) {
      referencedValue = '!ERROR'
    }
    return referencedValue
  } else if (`${value}`.startsWith('{') && `${value}`.endsWith('}')) {
    let referencedValue = ''
    try {
      referencedValue = resolveReferenceAsVariable({
        prepend: `${orgName}-`,
        path: `${value}`,
      }).cssVariable
    } catch (e) {
      referencedValue = '!ERROR'
    }
    return referencedValue
  }

  if (
    typeof value === 'number' &&
    typeof name === 'string' &&
    name !== 'font-weight'
  ) {
    return `${value}px`
  }

  return `${value}`
}

const resolveReferenceAsVariable = ({
  path,
  prepend,
}: {
  path: string
  prepend?: string
}) => {
  const keys = path
    .substring(1, path.length - 1)
    .split('.')
    .map((key) => key.toLowerCase())

  const cssVariable = `var(--${prepend ? prepend : ''}color-${keys
    .join('-')
    .split(' ')
    .join('-')})`
  return {
    cssVariable,
  }
}

export const flattenTheme = ({
  theme,
  metadata,
  colors,
  orgName,
}: {
  theme: TTheme
  metadata: TMetadata
  colors: TTokenGroup
  orgName: string
}) => {
  const colorValues: string[] = []

  const addNode = (node: TTokenGroup | TToken) => {
    if (assertTokenGroup(node)) {
      Object.keys(node).forEach((key) => {
        addNode(node[key])
      })
    } else if (assertToken(node)) {
      const resolvedValue = resolveTokenValue({
        value: node.value,
        colors,
        metadata,
        orgName,
      })

      colorValues.push(resolvedValue)
    }
  }

  addNode(theme.tokens)

  return colorValues
}
