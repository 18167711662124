import { RgbaColorPicker } from 'react-colorful'
import { AnyColor } from 'react-colorful/dist/types'
import tinycolor from 'tinycolor2'

interface Props {
  colorPickerColor: AnyColor
  onChange: (color: AnyColor) => void
  width: string
}

export default function RgbaPicker({
  colorPickerColor,
  onChange,
  width,
}: Props) {
  const rgbColor = tinycolor(colorPickerColor).toRgb()
  return (
    <RgbaColorPicker
      style={{
        width,
        height: '100%',
        minHeight: '275px',
      }}
      color={rgbColor}
      onChange={onChange}
    />
  )
}
