import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import React from 'react'

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'var(--background-color-primary)',
      },
    },
  },
  components: {
    Menu: {
      baseStyle: {
        list: {
          backgroundColor: 'var(--background-color-secondary)',
        },
        item: {
          backgroundColor: 'var(--background-color-secondary)',
          _hover: {
            backgroundColor: 'var(--background-color-primary)',
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          backgroundColor: 'var(--background-color-secondary)',
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: 'var(--color-input-border)',
            _hover: {
              borderColor: 'var(--color-input-border-hover)',
            },
            _focusVisible: {
              borderColor: 'var(--color-input-border-focus)',
              boxShadow: `none`,
            },
          },
        },
      },
    },
    Button: {
      variants: {
        primary: {
          backgroundImage: `linear-gradient(to top right, var(--color-violet-500), var(--color-violet-400))`,
          _disabled: {
            backgroundImage: `linear-gradient(to top right, var(--color-violet-300), var(--color-violet-400))`,
          },
          _hover: {
            backgroundImage: `linear-gradient(to top right, var(--color-violet-400), var(--color-violet-500))`,
            _disabled: {
              backgroundImage: `linear-gradient(to top right, var(--color-violet-300), var(--color-violet-400))`,
            },
          },
          _active: {
            backgroundImage: `linear-gradient(to top right, var(--color-violet-400), var(--color-violet-500))`,
          },
        },
        secondary: {
          color: 'white',
          backgroundImage: `linear-gradient(to top right, var(--color-sky-600), var(--color-sky-500))`,
          _disabled: {
            backgroundImage: `linear-gradient(to top right, var(--color-sky-300), var(--color-sky-400))`,
          },
          _hover: {
            backgroundImage: `linear-gradient(to top right, var(--color-sky-500), var(--color-sky-600))`,
            _disabled: {
              backgroundImage: `linear-gradient(to top right, var(--color-sky-300), var(--color-sky-400))`,
            },
          },
          _active: {
            backgroundImage: `linear-gradient(to top right, var(--color-sky-500), var(--color-sky-600))`,
          },
        },
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
    disableTransitionOnChange: false,
  },
})

export function MirrorfulThemeProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}
