import { Box, Button, Icon, useDisclosure } from '@chakra-ui/react'
import { useAuthInfo, useRedirectFunctions } from '@propelauth/react'
import { getOrgName, showPayingFeature } from '@web/context'
import { IconType } from 'react-icons'
import { FiCloudLightning, FiLifeBuoy, FiUser } from 'react-icons/fi'
import { HiOutlineSparkles } from 'react-icons/hi'

import { UpgradeModal } from './UpgradeModal'

function NavbarIcon({
  icon,
  onClick,
}: {
  icon: IconType
  onClick: () => void
}) {
  return (
    <Icon
      as={icon}
      css={{
        width: '20px',
        height: '20px',
        color: `var(--color-text-secondary)`,
        cursor: 'pointer',
      }}
      _hover={{
        color: `var(--color-text-secondary-accent)`,
      }}
      onClick={onClick}
    />
  )
}

export function Navbar() {
  const authInfo = useAuthInfo()
  const { redirectToOrgPage } = useRedirectFunctions()
  const {
    isOpen: isUpgradeOpen,
    onOpen: onUpgradeOpen,
    onClose: onUpgradeClose,
  } = useDisclosure()

  let style = { width: '120px' }
  let logoPath = '/mirrorful_logo.png'
  const orgName = getOrgName({ authInfo })
  if (orgName === 'localize') {
    logoPath = '/localize_logo.png'
  } else if (orgName === 'zenfetch') {
    logoPath = '/zf.png'
  } else if (orgName === 'concisely') {
    logoPath = '/concisely_logo.png'
  } else if (orgName === 'outerbase') {
    logoPath = '/outerbase_logo.png'
  } else if (orgName.includes('sweetgreen')) {
    logoPath = '/jitxlogo.png'
    style = { width: '70px' }
  }
  return (
    <Box
      css={{
        height: 50,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `0 var(--gutter-x)`,
        borderBottom: '1px solid var(--color-border)',
      }}
    >
      <img src={logoPath} style={style} />
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        {!showPayingFeature({ authInfo }) && (
          <Button
            leftIcon={<Icon as={FiCloudLightning} />}
            variant="primary"
            color={`white`}
            css={{
              height: '30px',
              fontSize: '14px',
              lineHeight: 1.2,
            }}
            onClick={() => {
              onUpgradeOpen()
            }}
            shadow="md"
          >
            Upgrade
          </Button>
        )}
        <NavbarIcon
          icon={HiOutlineSparkles}
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.open('https://www.designpatterns.io/ai')
            }
          }}
        />
        <NavbarIcon
          icon={FiLifeBuoy}
          onClick={() => {
            window.open('mailto:founders@mirrorful.io', '_blank')
          }}
        />
        <NavbarIcon
          icon={FiUser}
          onClick={() => {
            redirectToOrgPage()
          }}
        />
      </Box>
      <UpgradeModal isOpen={isUpgradeOpen} onClose={onUpgradeClose} />
    </Box>
  )
}
