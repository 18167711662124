import './vs2015.css'
import '../components/OuterbaseHardcode/outerbaseButton.css' // i think this import of outerbaseButton.css can be deleted?
import '@web/librarySource/projectAvatar/projectAvatar.css'
import './index.css'
import 'mirrorful-commit/dist/js/ui/styles.css'

import { CacheProvider } from '@chakra-ui/next-js'
import { useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import {
  AuthProvider,
  RedirectToLogin,
  RequiredAuthProvider,
  WithLoggedInAuthInfoProps,
  withRequiredAuthInfo,
} from '@propelauth/react'
import { useOAuth2 } from '@tasoskakour/react-use-oauth2'
import { ActivePanelProvider } from '@web/components/Context/ActivePanelContext'
import { SelectButtonVariantProvider } from '@web/components/Context/SelectedButtonVariantContext'
import { DebugPanel } from '@web/components/DebugPanel/DebugPanel'
import { GoToDesktopModal } from '@web/components/GoToDesktopModal'
import SplashScreen from '@web/components/SplashScreen'
import { MirrorfulThemeProvider } from '@web/components/ThemeProvider'
import BreakpointIndicator from '@web/utils/BreakpointIndicator'
import { MirrorfulPanel, MirrorfulProvider } from 'mirrorful-commit'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import posthog from 'posthog-js'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { MirrorfulWebWrapper } from '../components/MirrorfulWebWrapper'

if (typeof window !== 'undefined') {
  // This ensures that as long as we are client-side, posthog is always ready
  posthog.init('phc_Fi1SAV5Xhkmrf5VwIweTTmZDNnUIWmXkvXr7naLsNVV', {
    api_host: 'https://app.posthog.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
    },
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

type RequireAuthProps = {
  children: React.ReactNode
} & WithLoggedInAuthInfoProps

const RequireAuth = withRequiredAuthInfo(({ children }: RequireAuthProps) => {
  return <>{children}</>
})

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // To open the 'go to desktop app' if on mobile
  const { isOpen, onOpen, onClose } = useDisclosure()
  // For Commit
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    if (isMobile && !isOpen) {
      onOpen()
    }
  }, [onOpen, isOpen])

  return (
    <>
      <Head>
        <title>Mirrorful</title>
        <meta property="og:title" content="Mirrorful" key="title" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta
          property="og:image"
          content="https://mirrorful-production.s3.us-west-1.amazonaws.com/assets/1VisualDefine.png"
        />
        <meta
          property="og:description"
          content="Your custom design system without the work."
        />
        <meta
          name="description"
          content="Your custom design system without the work."
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="preload"
          href="https://mirrorful-production.s3.us-west-1.amazonaws.com/assets/components_graphic_dark.png"
        />
        <link
          rel="preload"
          href="https://mirrorful-production.s3.us-west-1.amazonaws.com/assets/components_graphic_light.png"
        />
        <link rel="preload" href="/semantic_tokens_graphic.png" />
        <link rel="preload" href="/themes_graphic.png" />
      </Head>

      <AuthProvider
        authUrl={
          process.env.NEXT_PUBLIC_AUTH_URL ??
          'https://607430308.propelauthtest.com'
        }
        // displayWhileLoading={<SplashScreen />}
        // displayIfLoggedOut={<RedirectToLogin />}
      >
        <MirrorfulProvider>
          <RequireAuth>
            <CacheProvider>
              <MirrorfulThemeProvider>
                <MirrorfulWebWrapper>
                  <ActivePanelProvider>
                    <SelectButtonVariantProvider>
                      <GoToDesktopModal isOpen={isOpen} onClose={onClose} />
                      <Component {...pageProps} />
                    </SelectButtonVariantProvider>
                  </ActivePanelProvider>
                </MirrorfulWebWrapper>
                {process.env.NODE_ENV === 'development' && <DebugPanel />}
                {/* {process.env.NODE_ENV === 'development' && (
                  <MirrorfulPanel
                    repo="localize-mirrorful"
                    owner="alexdanilowicz"
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                    githubClientId="aa0a4b0b9c812f0d7353"
                    prettierConfig={{
                      // NOTE: Copy paste your prettier config from your repo
                      tabWidth: 2,
                      singleQuote: true,
                      semi: false,
                      trailingComma: 'es5',
                    }}
                  />
                )} */}
              </MirrorfulThemeProvider>
            </CacheProvider>
          </RequireAuth>
        </MirrorfulProvider>
      </AuthProvider>
    </>
  )
}
