import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { TToken } from '@web/types'
import { useEffect, useState } from 'react'
import { AnyColor } from 'react-colorful/dist/types'

import ColorPicker from '../ColorPalette/ColorPicker'

interface UpdateColorModalProps {
  color: TToken | null
  isOpen: boolean
  onClose: () => void
  onUpdate: (name: string, color: string) => void
  name: string | null
}
const INITIAL_COLOR_PICKER_COLOR = '#008EC8'

export function UpdateColorModal({
  isOpen,
  color,
  onClose,
  name: defaultName,
  onUpdate,
}: UpdateColorModalProps) {
  const [colorPickerColor, setColorPickerColor] = useState<AnyColor>(
    color?.value || INITIAL_COLOR_PICKER_COLOR
  )
  const [name, setName] = useState<string>(defaultName || '')

  useEffect(() => {
    setColorPickerColor(color?.value || INITIAL_COLOR_PICKER_COLOR)
  }, [color])

  useEffect(() => {
    setName(defaultName || '')
  }, [defaultName])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Color</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          css={{
            flexDirection: 'row',
            display: 'flex',
            gap: 24,
          }}
        >
          <Flex flexDirection="column" flex="1" gap={4}>
            <FormControl>
              <Flex>
                <FormLabel>Color Name</FormLabel>
              </Flex>
              <Input
                size="md"
                placeholder="e.g. Sky Blue"
                value={name}
                onChange={function (event) {
                  setName(event.target.value)
                }}
              />
            </FormControl>
            <FormControl>
              <ColorPicker
                width="100%"
                shadow={false}
                onChange={(newColor) => {
                  setColorPickerColor(newColor)
                }}
                colorPickerColor={colorPickerColor}
              />
              <Box mt={5} style={{ width: '100%' }}>
                <FormControl
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    mt={2}
                    onClick={() => {
                      onUpdate(name, colorPickerColor as string)
                    }}
                  >
                    Update
                  </Button>
                </FormControl>
              </Box>
            </FormControl>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
