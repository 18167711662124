import { createContext, ReactNode, useContext, useState } from 'react'

type ActivePanelContextType = {
  activePanel: string | null
  setActivePanel: (value: string | null) => void
}

const ActivePanelContext = createContext<ActivePanelContextType | undefined>(
  undefined
)

type ActivePanelProviderProps = {
  children: ReactNode
}

function ActivePanelProvider({ children }: ActivePanelProviderProps) {
  const [activePanel, setActivePanel] = useState<string | null>(null)

  return (
    <ActivePanelContext.Provider value={{ activePanel, setActivePanel }}>
      {children}
    </ActivePanelContext.Provider>
  )
}

function useActivePanel(): ActivePanelContextType {
  const context = useContext(ActivePanelContext)
  if (!context) {
    throw new Error('useActivePanel must be used within a ActivePanelProvider')
  }
  return context
}

export { ActivePanelProvider, useActivePanel }
