import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { Navbar } from './Navbar'
import { Sidebar } from './Sidebar'

export function PlatformLayout({
  children,
  isLoading = false,
}: {
  children: React.ReactNode
  isLoading?: boolean
}) {
  return (
    <>
      <Box
        css={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Navbar />
        <Box css={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <Sidebar />
          <Box
            css={{
              flexGrow: 1,
              height: 'calc(100vh - 50px)',
              overflow: 'scroll',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  )
}
