import { CopyIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export function GoToDesktopModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const [tooltip, setTooltip] = useState('Copy')
  const [greetingIndex, setGreetingIndex] = useState(0)

  const greetings = [
    'Welcome to Mirrorful 👋', // English
    'Bienvenido a Mirrorful 👋', // Spanish
    'Bienvenue à Mirrorful 👋', // French
    'Willkommen bei Mirrorful 👋', // German
    'Benvenuto a Mirrorful 👋', // Italian
    'Bem-vindo ao Mirrorful 👋', // Portuguese
    'ようこそ、Mirrorfulへ 👋', // Japanese
    '欢迎来到 Mirrorful 👋', // Chinese Simplified
    "Mirrorful'e Hoş Geldiniz 👋", // Turkish
    'Selamat datang di Mirrorful 👋', // Indonesian
    'Mirrorful मा स्वागत छ 👋', // Nepali
    'خوش آمدید به Mirrorful 👋', // Persian
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setGreetingIndex((prevIndex) => (prevIndex + 1) % greetings.length)
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{greetings[greetingIndex]} </ModalHeader>
        <ModalBody>
          While our components are mobile-friendly, the best experience of our
          component editor is on desktop. See you there!
        </ModalBody>
        <ModalFooter>
          {/* <Flex alignItems="center" justifyContent="space-between"> */}
          {/* <Link
              href={'https://app.mirrorful.com'}
              isExternal
              style={{ fontWeight: 'bold', color: 'var(--color-sky-600)' }}
            >
              {'app.mirrorful.com'}
            </Link> */}
          {/* <Tooltip placement="top" closeDelay={500} hasArrow label={tooltip}>
              <CopyIcon
                style={{
                  color: 'black',
                  margin: 7,
                  zIndex: 2,
                }}
                cursor={'pointer'}
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    'https://app.mirrorful.com'
                  )
                  setTooltip('Copied')
                  setTimeout(() => {
                    setTooltip('Copy')
                  }, 1000)
                }}
              />
            </Tooltip> */}{' '}
          <Button
            variant="primary"
            css={{ fontSize: 14, height: 28, color: 'white' }}
            isDisabled={tooltip === 'Copied'}
            onClick={async () => {
              await navigator.clipboard.writeText('https://app.mirrorful.com')
              setTooltip('Copied')
              setTimeout(() => {
                setTooltip('Copy')
              }, 2000)
            }}
          >
            {tooltip === 'Copy' ? (
              <>Copy link to Mirrorful</>
            ) : (
              <>Copied link</>
            )}
          </Button>
          {/* </Flex> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
