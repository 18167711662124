import { toCss } from '@web/translators/toCss'
import { TPrimitives, TTheme } from '@web/types'

export function injectCssVarsIntoCurrentPage({
  primitives,
  themes,
  orgName,
}: {
  primitives: TPrimitives
  themes: TTheme[]
  orgName: string
}) {
  if (!document) {
    return
  }

  let ele = document.getElementById('mirrorful-injected')
  if (!ele) {
    ele = document.createElement('style')
    ele.id = 'mirrorful-injected'
    document.head.appendChild(ele)
  }

  const css = toCss(primitives, themes, `${orgName}-`)

  ele.textContent = css
}
