import { Box, Icon, Text } from '@chakra-ui/react'
import { useAuthInfo } from '@propelauth/react'
import { getOrgName, showPayingFeature, toTitleCase } from '@web/context'
import useMirrorfulStore from '@web/store/useMirrorfulStore'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { IconType } from 'react-icons'
import { BsTerminal } from 'react-icons/bs'
import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi'
import { MdOutlineColorLens } from 'react-icons/md'
import { RxComponent1 } from 'react-icons/rx'
import { TbColorSwatch } from 'react-icons/tb'

import Wishlist from './Wishlist'

function SidebarPrimaryLink({
  name,
  href,
  icon,
}: {
  name: string
  href: string
  icon: IconType
}) {
  const router = useRouter()
  const [isHovering, setIsHovering] = useState<boolean>(false)

  const isActive = router.asPath.includes(href)

  let textColor = 'var(--color-text-secondary)'
  if (isActive) {
    textColor = 'var(--color-text-primary-active)'
  } else if (isHovering) {
    textColor = 'var(--color-text-secondary-accent)'
  }

  return (
    <Link
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      href={href}
    >
      <Box
        css={{
          padding: 4,
          border:
            isHovering || isActive
              ? '1px solid var(--color-background-gradient-right)'
              : '1px solid var(--color-border)',
          width: 28,
          height: 28,
          borderRadius: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: textColor,

          backgroundImage:
            isHovering || isActive
              ? 'linear-gradient(45deg, var(--color-background-gradient-left), var(--color-background-gradient-right))'
              : 'none',
          transition: 'all 100ms ease-in-out',
        }}
        shadow="md"
      >
        <Icon
          as={icon}
          css={{ color: isHovering || isActive ? 'white' : 'inherit' }}
        />
      </Box>
      <Text
        css={{
          marginLeft: 8,
          fontWeight: 'bold',
          fontSize: '0.875rem',
          color: textColor,
        }}
      >
        {name}
      </Text>
    </Link>
  )
}

function SidebarSecondaryLink({
  name,
  href,
  shallow = false,
}: {
  name: string
  href: string
  shallow?: boolean
}) {
  const router = useRouter()
  const [isHovering, setIsHovering] = useState<boolean>(false)

  const isActive = router.asPath.endsWith(href)

  useEffect(() => {
    if (isActive && linkRef.current) {
      setTimeout(() => {
        linkRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 0) // timeout needed because otherwise doesnt work on page load
    }
  }, [isActive])

  const linkRef = useRef<HTMLAnchorElement>(null)

  return (
    <Link
      ref={linkRef}
      style={{
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        color: isActive
          ? 'var(--color-primary-accent)'
          : 'var(--color-text-secondary)',
        margin: '0px -10px',
        padding: '6px 10px',
        borderRadius: 4,
        fontWeight: isActive ? 'bold' : 'normal',
        backgroundColor:
          isHovering || isActive
            ? 'var(--color-background-active)'
            : 'transparent',
      }}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      href={href}
      shallow={shallow}
    >
      {name}
    </Link>
  )
}

function SidebarSection({
  title,
  linkMatch,
  children,
}: {
  title: string
  linkMatch?: string
  children: React.ReactNode
}) {
  const router = useRouter()

  const isActive = router.asPath.includes(
    linkMatch ? `/${linkMatch}/` : `/${title.toLowerCase()}/`
  )

  const [isHovering, setIsHovering] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(isActive)

  return (
    <Box>
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          cursor: 'pointer',
          color: isHovering
            ? 'var(--color-primary-accent)'
            : 'var(--color-text-secondary)',
          margin: '0px -10px',
          padding: '6px 10px',
          borderRadius: 4,
          backgroundColor: isHovering
            ? 'var(--color-background-active)'
            : 'transparent',
        }}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <Icon
          as={isOpen ? FiChevronDown : FiChevronRight}
          css={{ marginRight: '8px' }}
        />
        {title}
      </Box>
      <motion.div
        animate={{ height: isOpen ? 'auto' : 0 }}
        style={{
          paddingLeft: '12px',
          overflowY: 'hidden',
          overflowX: 'visible',
        }}
      >
        {children}
      </motion.div>
    </Box>
  )
}

export function Sidebar() {
  const router = useRouter()
  const authInfo = useAuthInfo()
  const isComponents = router.pathname.includes('/components')
  const isColors = router.pathname.includes('/colors')
  const isThemes = router.pathname.includes('/themes')
  const isDeploy = router.pathname.includes('/deploy')

  return (
    <Box
      css={{
        width: 250,
        display: 'flex',
        flexDirection: 'column',
        padding: 'var(--padding-y)  var(--gutter-x)',
        gap: 32,
        fontSize: '0.875rem',
        height: 'calc(100vh - 50px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
        borderRight: '1px solid var(--color-border)',
      }}
    >
      <Box css={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
        <SidebarPrimaryLink
          name="Components"
          href="/components"
          icon={RxComponent1}
        />
        <SidebarPrimaryLink
          name="Colors"
          href="/colors"
          icon={MdOutlineColorLens}
        />
        <SidebarPrimaryLink name="Themes" href="/themes" icon={TbColorSwatch} />
        {getOrgName({ authInfo }) !== 'outerbase' && (
          <SidebarPrimaryLink name="Deploy" href="/deploy" icon={BsTerminal} />
        )}
      </Box>
      {isComponents && <SidebarComponentsSection />}
      {isColors && <SidebarColorsSection />}
      {isThemes && <SidebarThemesSection />}
      {isDeploy && <SidebarDeploySection />}
    </Box>
  )
}

function SidebarComponentsSection() {
  const authInfo = useAuthInfo()
  const orgName = getOrgName({ authInfo })

  let listOfComponents = (
    <>
      <SidebarSecondaryLink name="Accordion" href="/components/accordion" />
      <SidebarSecondaryLink name="Avatar" href="/components/avatar" />
      <SidebarSecondaryLink name="Badge" href="/components/badge" />
      <SidebarSecondaryLink name="Breadcrumbs" href="/components/breadcrumbs" />
      <SidebarSecondaryLink name="Button" href="/components/button" />
      <SidebarSecondaryLink name="Card" href="/components/card" />
      <SidebarSecondaryLink name="Calendar" href="/components/calendar" />
      <SidebarSecondaryLink name="Checkbox" href="/components/checkbox" />
      <SidebarSecondaryLink name="Chip" href="/components/chip" />
      <SidebarSecondaryLink name="Data Table" href="/components/dataTable" />
      <SidebarSecondaryLink name="Date Picker" href="/components/datePicker" />
      <SidebarSecondaryLink name="Icon" href="/components/icon" />

      <SidebarSecondaryLink name="Input" href="/components/input" />
      <SidebarSecondaryLink name="Modal" href="/components/modal" />
      <SidebarSecondaryLink
        name="Notification Banner"
        href="/components/notificationBanner"
      />
      <SidebarSecondaryLink name="Pagination" href="/components/pagination" />
      <SidebarSecondaryLink name="Popover" href="/components/popover" />
      {orgName === 'localize' && (
        <SidebarSecondaryLink
          name="Project Avatar"
          href="/components/projectAvatar"
        />
      )}
      <SidebarSecondaryLink name="Radio Item" href="/components/radioItem" />
      <SidebarSecondaryLink name="Search" href="/components/search" />
      <SidebarSecondaryLink name="Table" href="/components/table" />
      <SidebarSecondaryLink name="Tabs" href="/components/tabs" />
      <SidebarSecondaryLink name="Toggle" href="/components/toggle" />
      <SidebarSecondaryLink name="Tooltip" href="/components/tooltip" />
    </>
  )
  if (orgName === 'outerbase') {
    listOfComponents = (
      <>
        <SidebarSection title="Buttons">
          <SidebarSecondaryLink
            name="Base Button"
            href="/components/ob/buttons/baseButton"
          />
          <SidebarSecondaryLink
            name="Cancel Button"
            href="/components/ob/buttons/cancelButton"
          />
          <SidebarSecondaryLink
            name="Purple Button"
            href="/components/ob/buttons/purpleButton"
          />
          <SidebarSecondaryLink
            name="Danger Button"
            href="/components/ob/buttons/dangerButton"
          />
          <SidebarSecondaryLink
            name="Square Button"
            href="/components/ob/buttons/squareButton"
          />
          <SidebarSecondaryLink
            name="Small Square Button"
            href="/components/ob/buttons/smallSquareButton"
          />
          <SidebarSecondaryLink
            name="High Contrast Button"
            href="/components/ob/buttons/highContrastButton"
          />
          <SidebarSecondaryLink
            name="Copyable Button"
            href="/components/ob/buttons/copyableButton"
          />
          <SidebarSecondaryLink
            name="Up Down Button"
            href="/components/ob/buttons/upDownButton"
          />
        </SidebarSection>
        <SidebarSection title="Inputs">
          <SidebarSecondaryLink
            name="Input"
            href="/components/ob/inputs/input"
          />
          <SidebarSecondaryLink
            name="Labeled Input"
            href="/components/ob/inputs/labeledInput"
          />
          <SidebarSecondaryLink
            name="Subtle Input"
            href="/components/ob/inputs/subtleInput"
          />
          <SidebarSecondaryLink
            name="Labeled Email Input"
            href="/components/ob/inputs/labeledEmailInput"
          />
          <SidebarSecondaryLink
            name="Labeled Password Input"
            href="/components/ob/inputs/labeledPasswordInput"
          />
          <SidebarSecondaryLink
            name="Controlled Input"
            href="/components/ob/inputs/controlledInput"
          />
          <SidebarSecondaryLink
            name="Input on Double Click"
            href="/components/ob/inputs/inputOnDoubleClick"
          />
        </SidebarSection>
        <SidebarSection title="Menu Dropdowns" linkMatch="menuDropdowns">
          <SidebarSecondaryLink
            name="Account Dropdown"
            href="/components/ob/menuDropdowns/accountDropdown"
          />
          <SidebarSecondaryLink
            name="Add Chart Dropdown"
            href="/components/ob/menuDropdowns/addChartDropdown"
          />
          <SidebarSecondaryLink
            name="Generic Dropdown Menu"
            href="/components/ob/menuDropdowns/genericDropdownMenu"
          />
          <SidebarSecondaryLink
            name="Tab Create Menu"
            href="/components/ob/menuDropdowns/tabCreateMenu"
          />
        </SidebarSection>
        <SidebarSection title="Modals">
          <SidebarSecondaryLink
            name="Base Modal"
            href="/components/ob/modals/baseModal"
          />
          <SidebarSecondaryLink
            name="Rename Base Modal"
            href="/components/ob/modals/renameBaseModal"
          />
          <SidebarSecondaryLink
            name="Delete Base Modal"
            href="/components/ob/modals/deleteBaseModal"
          />
          <SidebarSecondaryLink
            name="Fullscreen Modal"
            href="/components/ob/modals/fullscreenModal"
          />
          <SidebarSecondaryLink
            name="Minimal Modal"
            href="/components/ob/modals/minimalModal"
          />
          <SidebarSecondaryLink
            name="Alert Modal"
            href="/components/ob/modals/alertModal"
          />
        </SidebarSection>
        <SidebarSecondaryLink
          name="Action Bar"
          href="/components/ob/actionBar"
        />
        <SidebarSecondaryLink
          name="Label Selector"
          href="/components/ob/labelSelector"
        />
        <SidebarSecondaryLink
          name="Line Separator"
          href="/components/ob/lineSeparator"
        />
        <SidebarSecondaryLink name="Link" href="/components/ob/link" />
        <SidebarSecondaryLink
          name="Loading Spinner"
          href="/components/ob/loadingSpinner"
        />
        <SidebarSecondaryLink
          name="Plugin Selector"
          href="/components/ob/pluginSelector"
        />
        <SidebarSecondaryLink
          name="User Avatar"
          href="/components/ob/userAvatar"
        />
      </>
    )
  }

  if (orgName.toLowerCase().includes('jitx')) {
    listOfComponents = (
      <>
        <SidebarSecondaryLink
          name="Accordion"
          href="/components/chakra/accordion"
        />
        <SidebarSecondaryLink name="Alert" href="/components/chakra/alert" />
        <SidebarSecondaryLink name="Badge" href="/components/chakra/badge" />
        <SidebarSecondaryLink name="Button" href="/components/chakra/button" />
        <SidebarSecondaryLink
          name="Checkbox"
          href="/components/chakra/checkbox"
        />
        <SidebarSecondaryLink
          name="Circular Progress"
          href="/components/chakra/circularProgress"
        />
        <SidebarSecondaryLink
          name="Editable"
          href="/components/chakra/editable"
        />
        <SidebarSecondaryLink
          name="Icon Button"
          href="/components/chakra/iconButton"
        />
        <SidebarSecondaryLink name="Input" href="/components/chakra/input" />
        <SidebarSecondaryLink name="Modal" href="/components/chakra/modal" />
        <SidebarSecondaryLink
          name="Pin Input"
          href="/components/chakra/pinInput"
        />
        <SidebarSecondaryLink
          name="Popover"
          href="/components/chakra/popover"
        />
        <SidebarSecondaryLink
          name="Progress"
          href="/components/chakra/progress"
        />
        <SidebarSecondaryLink name="Radio" href="/components/chakra/radio" />
        <SidebarSecondaryLink
          name="Range Slider"
          href="/components/chakra/rangeSlider"
        />

        <SidebarSecondaryLink name="Select" href="/components/chakra/select" />
        <SidebarSecondaryLink name="Stats" href="/components/chakra/stats" />
        <SidebarSecondaryLink name="Switch" href="/components/chakra/switch" />
        <SidebarSecondaryLink name="Tabs" href="/components/chakra/tabs" />
        <SidebarSecondaryLink
          name="Textarea"
          href="/components/chakra/textarea"
        />
        <SidebarSecondaryLink
          name="Tooltip"
          href="/components/chakra/tooltip"
        />
      </>
    )
  }

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <Text css={{ fontWeight: 'bold', fontSize: '1rem' }}>Components</Text>
      <Box css={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {listOfComponents}
      </Box>
      {!showPayingFeature({ authInfo }) && (
        <Box
          css={{
            paddingBottom: '24px',
            paddingTop: '12px',
            color: 'var(--color-text-secondary)',
            cursor: 'pointer',
          }}
          _hover={{
            textDecoration: 'underline',
          }}
          onClick={() => {
            window.open('https://mirrorful.com/contact', '_blank')
          }}
        >
          Need something custom?
        </Box>
      )}
      <Wishlist />
    </Box>
  )
}

function SidebarColorsSection() {
  const { colors } = useMirrorfulStore()

  const colorNames = Object.keys(colors)

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <Text css={{ fontWeight: 'bold', fontSize: '1rem' }}>Colors</Text>
      <Box css={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {colorNames.map((colorName) => (
          <SidebarSecondaryLink
            key={colorName}
            name={colorName}
            href={`/colors#${colorName.toLowerCase().replaceAll(' ', '-')}`}
            shallow
          />
        ))}
      </Box>
    </Box>
  )
}

function SidebarThemesSection() {
  const { themes } = useMirrorfulStore()

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <Text css={{ fontWeight: 'bold', fontSize: '1rem' }}>Themes</Text>
      <Box css={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <SidebarSecondaryLink name={'Overview'} href={`/themes`} />
        {themes.map((theme) => (
          <SidebarSecondaryLink
            key={theme.id}
            name={theme.name}
            href={`/themes/${theme.id}`}
          />
        ))}
      </Box>
    </Box>
  )
}

function SidebarDeploySection() {
  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <Text css={{ fontWeight: 'bold', fontSize: '1rem' }}>Deploy</Text>
      <Box css={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* <SidebarSecondaryLink name={'Github PR'} href={`/deploy#github`} /> */}
        <SidebarSecondaryLink
          name={'Code Source'}
          href={`/deploy#codeSource`}
        />
        {/* <SidebarSecondaryLink
          name={'NPM Package'}
          href={`/deploy#npmPackage`}
        />
        <SidebarSecondaryLink name={'CLI'} href={`/deploy#cli`} /> */}
      </Box>
    </Box>
  )
}
