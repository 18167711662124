import { TTokenGroup } from '@web/types'

export const VERSION = '1.3.16'

export const DEFAULT_GREY_COLOR: TTokenGroup = {
  DEFAULT: {
    id: 'default-grey-color-default-id',
    value: '#94a3b8',
    type: 'color',
  },
  50: {
    id: 'default-grey-color-50-id',
    value: '#f9fafb',
    type: 'color',
  },
  100: {
    id: 'default-grey-color-100-id',
    value: '#f1f5f9',
    type: 'color',
  },
  200: {
    id: 'default-grey-color-200-id',
    value: '#e2e8f0',
    type: 'color',
  },
  300: {
    id: 'default-grey-color-300-id',
    value: '#cbd5e1',
    type: 'color',
  },
  400: {
    id: 'default-grey-color-400-id',
    value: '#94a3b8',
    type: 'color',
  },
  500: {
    id: 'default-grey-color-500-id',
    value: '#64748b',
    type: 'color',
  },
  600: {
    id: 'default-grey-color-600-id',
    value: '#475569',
    type: 'color',
  },
  700: {
    id: 'default-grey-color-700-id',
    value: '#334155',
    type: 'color',
  },
  800: {
    id: 'default-grey-color-800-id',
    value: '#1e293b',
    type: 'color',
  },
  900: {
    id: 'default-grey-color-900-id',
    value: '#0f172a',
    type: 'color',
  },
  950: {
    id: 'default-grey-color-950-id',
    value: '#030712',
    type: 'color',
  },
}

export const DEFAULT_GREEN_COLOR: TTokenGroup = {
  DEFAULT: {
    id: 'default-green-color-default-id',
    value: '#34d399',
    type: 'color',
  },
  50: {
    id: 'default-green-color-50-id',
    value: '#ecfdf5',
    type: 'color',
  },
  100: {
    id: 'default-green-color-100-id',
    value: '#d1fae5',
    type: 'color',
  },
  200: {
    id: 'default-green-color-200-id',
    value: '#a7f3d0',
    type: 'color',
  },
  300: {
    id: 'default-green-color-300-id',
    value: '#6ee7b7',
    type: 'color',
  },
  400: {
    id: 'default-green-color-400-id',
    value: '#34d399',
    type: 'color',
  },
  500: {
    id: 'default-green-color-500-id',
    value: '#10b981',
    type: 'color',
  },
  600: {
    id: 'default-green-color-600-id',
    value: '#059669',
    type: 'color',
  },
  700: {
    id: 'default-green-color-700-id',
    value: '#047857',
    type: 'color',
  },
  800: {
    id: 'default-green-color-800-id',
    value: '#065f46',
    type: 'color',
  },
  900: {
    id: 'default-green-color-900-id',
    value: '#064e3b',
    type: 'color',
  },
  950: {
    id: 'default-green-color-950-id',
    value: '#022d1f',
    type: 'color',
  },
}

export const DEFAULT_RED_COLOR: TTokenGroup = {
  DEFAULT: {
    id: 'default-red-color-default-id',
    value: '#db6372',
    type: 'color',
  },
  50: {
    id: 'default-red-color-50-id',
    value: '#efbac1',
    type: 'color',
  },
  100: {
    id: 'default-red-color-100-id',
    value: '#ecaeb6',
    type: 'color',
  },
  200: {
    id: 'default-red-color-200-id',
    value: '#e6919c',
    type: 'color',
  },
  300: {
    id: 'default-red-color-300-id',
    value: '#df7482',
    type: 'color',
  },
  400: {
    id: 'default-red-color-400-id',
    value: '#db6372',
    type: 'color',
  },
  500: {
    id: 'default-red-color-500-id',
    value: '#d34053',
    type: 'color',
  },
  600: {
    id: 'default-red-color-600-id',
    value: '#bd2b3e',
    type: 'color',
  },
  700: {
    id: 'default-red-color-700-id',
    value: '#ab2738',
    type: 'color',
  },
  800: {
    id: 'default-red-color-800-id',
    value: '#8e212f',
    type: 'color',
  },
  900: {
    id: 'default-red-color-900-id',
    value: '#711a25',
    type: 'color',
  },
}
