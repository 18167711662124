import { MirrorfulButtonVariant } from '@mirrorful-fern/api-client/api'
import React, { createContext, ReactNode, useContext, useState } from 'react'

type SelectedVariantType = MirrorfulButtonVariant | null

interface SelectButtonVariantContextType {
  selectedVariant: SelectedVariantType
  setSelectedVariant: React.Dispatch<React.SetStateAction<SelectedVariantType>>
}

const SelectButtonVariantContext = createContext<
  SelectButtonVariantContextType | undefined
>(undefined)

interface SelectButtonVariantProviderProps {
  children: ReactNode
}

export const SelectButtonVariantProvider: React.FC<
  SelectButtonVariantProviderProps
> = ({ children }) => {
  const [selectedVariant, setSelectedVariant] =
    useState<SelectedVariantType>(null)

  return (
    <SelectButtonVariantContext.Provider
      value={{ selectedVariant, setSelectedVariant }}
    >
      {children}
    </SelectButtonVariantContext.Provider>
  )
}

export const useSelectButtonVariant = (): SelectButtonVariantContextType => {
  const context = useContext(SelectButtonVariantContext)
  if (!context) {
    throw new Error(
      'useSelectButtonVariant must be used within a SelectButtonVariantProvider'
    )
  }
  return context
}
