import { useColorMode } from '@chakra-ui/react'
import { useAuthInfo } from '@propelauth/react'
import { UseAuthInfoProps } from '@propelauth/react/dist/types/useAuthInfo'
import * as Sentry from '@sentry/nextjs'
import { getStore, postStoreData } from '@web/client/store'
import { Onboarding } from '@web/components/Onboarding'
import SplashScreen from '@web/components/SplashScreen'
import { getOrgName } from '@web/context'
import useMirrorfulStore, { MirrorfulState } from '@web/store/useMirrorfulStore'
import {
  defaultShadowsV2,
  TMirrorfulStore,
  TPrimitives,
  TTheme,
} from '@web/types'
import { injectCssVarsIntoCurrentPage } from '@web/utils/injectCssIntoCurrentPage'
import { useRouter } from 'next/navigation'
import posthog from 'posthog-js'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { PlatformLayout } from './Platform/PlatformLayout'

export function MirrorfulWebWrapper({ children }: { children: ReactNode }) {
  const authInfo = useAuthInfo()
  const [isLoading, setIsLoading] = useState(true)
  const [shouldForceSkipOnboarding, setShouldForceSkipOnboarding] =
    useState(false)
  const [showOnBoarding, setShowOnBoarding] = useState(false)
  const router = useRouter()
  const { colorMode, toggleColorMode } = useColorMode()

  const user = authInfo.user
  const org = getOrgName({ authInfo })

  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode()
    }
  }, [colorMode, toggleColorMode])

  useEffect(() => {
    const name = user?.firstName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.username
    if (user) {
      posthog.identify(user.userId, { email: user.email, name: name })
    }
    posthog?.group('organization', org)
  }, [user, org])

  const {
    setColors,
    setTypography,
    setShadows,
    setFileTypes,
    setThemes,
    setMetadata,
    onLoad,
  } = useMirrorfulStore((state: MirrorfulState) => state)

  // to fetch data
  const timeout = useRef<NodeJS.Timeout | null>(null)

  /**
   * NOTE THE EARLY RETURN
   * MOST OF THIS LOGIC DOES NOT RUN UNTIL A STORE EXISTS
   */
  const fetchStoredData = useCallback(async () => {
    try {
      setIsLoading(true)
      const data = await getStore({
        authInfo: authInfo,
      })
      if (
        !data ||
        Object.keys(data).length === 0 ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Object.keys(data.primitives.colors).length === 0
      ) {
        setShowOnBoarding(true)
        return
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setThemes(data.themes ?? [])
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setColors(data.primitives.colors ?? {})
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setTypography(data.primitives.typography)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setShadows(data.primitives.shadows ?? defaultShadowsV2)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setFileTypes(data.files)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setMetadata(data.metadata)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (Object.keys(data.primitives.colors) === 0) {
        Sentry.captureException(`Colors loaded in empty in store`)
      }

      if (getOrgName({ authInfo }) === 'localize') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const colors = data.primitives?.colors
        if (!('Boston Blue' in colors)) {
          Sentry.captureException(
            `[Urgent] Boston Blue not in localize's store`
          )
        }
      }

      injectCssVarsIntoCurrentPage({
        primitives: data.primitives as TPrimitives,
        themes: data.themes as TTheme[],
        orgName: getOrgName({ authInfo }),
      })

      onLoad()
    } catch (e) {
      throw e
    } finally {
      timeout.current = setTimeout(() => {
        setIsLoading(false)
        onLoad()
      }, 1250)
    }
  }, [
    authInfo,
    setThemes,
    setColors,
    setTypography,
    setShadows,
    setFileTypes,
    setMetadata,
    onLoad,
  ])

  useEffect(() => {
    // on initial load
    fetchStoredData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    router.prefetch('/components')
  }, [router])

  const handleOnboardingSubmit = async (
    data: TMirrorfulStore,
    authInfo: UseAuthInfoProps
  ) => {
    await postStoreData({
      newData: data,
      authInfo: authInfo,
    })
    setColors(data.primitives.colors)
    setShadows(data.primitives.shadows)
    setTypography(data.primitives.typography)
    setFileTypes(data.files)
    setMetadata(data.metadata)
    injectCssVarsIntoCurrentPage({
      primitives: data.primitives as TPrimitives,
      themes: data.themes as TTheme[],
      orgName: getOrgName({ authInfo }),
    })
  }

  return (
    <>
      {(isLoading || authInfo.loading) && <SplashScreen />}
      {!shouldForceSkipOnboarding && showOnBoarding ? (
        <Onboarding
          postStore={handleOnboardingSubmit}
          onFinishOnboarding={() => {
            setShowOnBoarding(false)
            setShouldForceSkipOnboarding(true)
          }}
          platform={'web'}
        />
      ) : (
        <PlatformLayout>{children}</PlatformLayout>
      )}
    </>
  )
}
