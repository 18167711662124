import { HexColorPicker } from 'react-colorful'
import { AnyColor } from 'react-colorful/dist/types'
import tinycolor from 'tinycolor2'

interface Props {
  colorPickerColor: AnyColor
  onChange: (color: AnyColor) => void
  width: string
}

export default function HexPicker({
  colorPickerColor,
  onChange,
  width,
}: Props) {
  return (
    <HexColorPicker
      style={{
        width,
        height: '100%',
        minHeight: '275px',
      }}
      color={tinycolor(colorPickerColor).toHexString()}
      onChange={onChange}
    />
  )
}
