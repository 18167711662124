import {
  MirrorfulApiClient,
  MirrorfulApiEnvironment,
} from '@mirrorful-fern/api-client'
import { UseAuthInfoProps } from '@propelauth/react/dist/types/useAuthInfo'
import * as Sentry from '@sentry/nextjs'
import { getOrgName } from '@web/context'
import { TMirrorfulStore } from '@web/types'

const environment =
  process.env.NODE_ENV === 'production'
    ? MirrorfulApiEnvironment.Production
    : MirrorfulApiEnvironment.Development

const orgId = '123'

export async function getStore({ authInfo }: { authInfo: UseAuthInfoProps }) {
  const accessToken = authInfo.accessToken
  const client = new MirrorfulApiClient({
    environment,
    token: `${accessToken}`,
  })
  try {
    const storeId = `${getOrgName({ authInfo })}-prod`
    return await client.store.getStore(orgId, storeId)
  } catch (e) {
    return null
  }
}

export async function postStoreData({
  newData,
  authInfo,
}: {
  newData: TMirrorfulStore
  authInfo: UseAuthInfoProps
}) {
  const accessToken = authInfo.accessToken
  const client = new MirrorfulApiClient({
    environment,
    token: `${accessToken}`,
  })
  const storeId = `${getOrgName({ authInfo })}-prod`

  if (Object.keys(newData.primitives.colors).length === 0) {
    Sentry.captureException('Prevented empty colors object from being saved.')
    return
  }
  await client.store.updateStore(orgId, storeId, newData)
}
