import { UseAuthInfoProps } from '@propelauth/react/dist/types/useAuthInfo'
import * as Sentry from '@sentry/nextjs'

/**
 * NOTE: right now we assume the user is only part of one org
 * If urlSafe is true, then org name with a space will have a hyphen instead
 */
export const getOrgName = ({
  authInfo,
  urlSafe = true,
}: {
  authInfo: UseAuthInfoProps
  urlSafe?: boolean
}) => {
  if (process.env.NODE_ENV === 'development') {
    if (window) {
      const orgOverride = window.localStorage.getItem('overrideOrgName')
      if (orgOverride) {
        return orgOverride
      }
    }
  }

  // in the future we should use: https://docs.propelauth.com/reference/frontend-apis/react#active-organization
  const user = authInfo.orgHelper?.getOrgs()
  let orgName = user?.[0]?.urlSafeOrgName // this is important, because this is used for css
  if (!urlSafe) {
    orgName = user?.[0]?.orgName
  }
  if (!orgName) {
    Sentry.captureException(`No orgName found`, { extra: { user } })
    return 'demo'
  } else {
    return orgName.toLowerCase()
  }
}

export const toTitleCase = (str: string) => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export const showGithubFeatureExperiment = ({
  authInfo,
}: {
  authInfo: UseAuthInfoProps
}) => {
  const orgName = getOrgName({ authInfo })
  return [
    'mirrorful',
    'zenfetch',
    'sweetgreen',
    'sweetgreenjitx',
    'sweetgreenjitxtwo',
  ].includes(orgName)
}

export const showPayingFeature = ({
  authInfo,
}: {
  authInfo: UseAuthInfoProps
}) => {
  const PAYING_ORGS_URL_SAFE_NAME = [
    'localize',
    'zenfetch',
    'prepared',
    'concisely',
    'danilowicz-demo', // note it's the url safe name
    'outerbase',
    'mirrorful',
    'sweetgreen',
    'sweetgreenjitx',
    'sweetgreenjitxtwo',
  ]
  const orgName = getOrgName({ authInfo })

  return PAYING_ORGS_URL_SAFE_NAME.includes(orgName)
}
