import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { FiSettings } from 'react-icons/fi'

import { OverrideOrgName } from './OverrideOrgName'

export function DebugPanel() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box
        css={{
          position: 'fixed',
          bottom: '25px',
          right: '25px',
          zIndex: 99999,
          width: '30px',
          height: '30px',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backdropFilter: 'blur(4px)',
          borderRadius: 2,
          cursor: 'pointer',
          transition: 'background-color 0.2s ease-in-out',
        }}
        _hover={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        onClick={onOpen}
      >
        <Icon as={FiSettings} css={{ color: 'white' }} />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Debug Panel</ModalHeader>
          <ModalCloseButton />
          <ModalBody css={{ paddingBottom: '48px' }}>
            <OverrideOrgName />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
