import { resolveTokenValueForGeneration } from '@web/components/Themes/themeUtils'
import {
  assertToken,
  assertTokenGroup,
  TPrimitives,
  TTheme,
  TToken,
  TTokenGroup,
} from '@web/types'

import { sanitizeName } from './sanitizeName'

export const toCss = (
  { colors, typography, shadows }: TPrimitives,
  themes: TTheme[],
  prependVariable = ''
): string => {
  // Adding preflight stuff.
  const content = [
    `/* Preflight */
body {
  font-family: 'museo-sans', sans-serif;
} \n`,
  ]

  content.push(':root {')
  content.push('  /* Primitives */')
  Object.keys(colors).forEach((colorName) => {
    const color = colors[colorName]
    if (assertTokenGroup(color)) {
      Object.keys(color).forEach((variantName) => {
        content.push(
          `  --${prependVariable}color-${sanitizeName(
            colorName
          )}-${sanitizeName(variantName)}: ${color[variantName].value};`
        )
      })
    }
  })

  Object.keys(typography.fontSizes).forEach((name) => {
    const fontSize = typography.fontSizes[name]
    if (assertToken(fontSize)) {
      content.push(
        `  --${prependVariable}font-size-${sanitizeName(name)}: ${
          fontSize.value
        };`
      )
    }
  })

  Object.keys(typography.fontWeights).forEach((name) => {
    const fontWeight = typography.fontWeights[name]
    if (assertToken(fontWeight)) {
      content.push(
        `  --${prependVariable}font-weight-${sanitizeName(name)}: ${
          fontWeight.value
        };`
      )
    }
  })

  Object.keys(typography.lineHeights).forEach((name) => {
    const lineHeight = typography.lineHeights[name]
    if (assertToken(lineHeight)) {
      content.push(
        `  --${prependVariable}line-height-${sanitizeName(name)}: ${
          lineHeight.value
        };`
      )
    }
  })

  Object.keys(shadows).forEach((name) => {
    const shadow = shadows[name]
    if (assertToken(shadow)) {
      content.push(
        `  --${prependVariable}box-shadow-${sanitizeName(name)}: ${
          shadow.value
        };`
      )
    }
  })

  if (themes.length > 0) {
    content.push('')
    content.push('  /* Default Theme */')
    const defaultTheme = themes[0]

    // Recursively iterate through the theme
    const themeTokens = defaultTheme.tokens

    const addNode = (node: TTokenGroup | TToken, path: string) => {
      if (assertTokenGroup(node)) {
        Object.keys(node).forEach((key) => {
          addNode(node[key], path.length > 0 ? `${path}-${key}` : key)
        })
      } else if (assertToken(node)) {
        const resolvedValue = resolveTokenValueForGeneration({
          prepend: prependVariable,
          value: node.value,
        })

        content.push(
          `  --${prependVariable}${path
            .split(' ')
            .join('-')}: ${resolvedValue};`
        )
      }
    }

    addNode(themeTokens, '')
  }

  content.push(`}`)
  content.push('')

  themes.forEach((theme) => {
    content.push(`html[data-theme='${sanitizeName(theme.name)}'] {`)

    // Recursively iterate through the theme
    const themeTokens = theme.tokens

    const addNode = (node: TTokenGroup | TToken, path: string) => {
      if (assertTokenGroup(node)) {
        Object.keys(node).forEach((key) => {
          addNode(node[key], path.length > 0 ? `${path}-${key}` : key)
        })
      } else if (assertToken(node)) {
        const resolvedValue = resolveTokenValueForGeneration({
          prepend: prependVariable,
          value: node.value,
        })

        content.push(`  --${prependVariable}${path}: ${resolvedValue};`)
      }
    }

    addNode(themeTokens, '')

    content.push('}')
  })

  return content.join('\n')
}
